<template>
  <div class="assessment-creator">
    <div class="sub-header">
      <span v-if="$route.name === 'assessment-creator'">Assessment Creator</span>
      <span v-if="$route.name === 'create-assessment' || $route.name === 'successful-assessment-submission'"
        >Create Assessment</span
      >
      <span v-if="$route.name === 'edit-assessment'">Edit Assessment</span>
    </div>
    <router-view class="sub-route" />
  </div>
</template>

<script>
export default {
  name: 'assessment-creator',
};
</script>

<style lang="scss" scoped>
.assessment-creator {
  .sub-header {
    width: 100%;
    padding: 35px 0;
    background: white;
    span {
      margin: auto 0 auto 35px;
      font-size: 20px;
    }
  }
  > .sub-route {
    padding: 35px 75px;
  }
}
</style>
